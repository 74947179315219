import "./scroll.css";

import { useState, useEffect } from "react";
import { BsArrowUpCircle } from "react-icons/bs";

function Scroll({ modalOpen }) {
  const [btnVisible, setBtnVisible] = useState(false);

  useEffect(() => {
    const watch = () => {
      window.addEventListener("scroll", handlePage);
    };

    watch();

    return () => {
      window.removeEventListener("scroll", handlePage);
    };
  });

  const handlePage = () => {
    window.pageYOffset > 400 ? setBtnVisible(true) : setBtnVisible(false);
  };

  const handleTop = () => {
    // if modal on -> scroll is not working
    if (!modalOpen) {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
      setBtnVisible(false);
    }
  };

  return (
    <span className={btnVisible ? "visible" : "invisible"} onClick={handleTop}>
      <BsArrowUpCircle className="scrollup" />
    </span>
  );
}

export default Scroll;

import "./contact.css";
import { useRef, useState } from "react";

import { MdOutlineMarkEmailUnread } from "react-icons/md";
import { AiOutlineLinkedin } from "react-icons/ai";
import { SiNotion } from "react-icons/si";

import emailjs from "@emailjs/browser";

import Popup from "../error/Popup";
import Scroll from "../scroll/Scroll";

function Contact() {
  const [isVerified, setIsVerified] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [answer, setAnswer] = useState("");

  const form = useRef();
  const errRef = useRef();

  const checkAnswer = (e) => {
    setAnswer(e.target.value);

    const ANSWER = e.target.value.trim();

    if (ANSWER === (5 * 5).toFixed()) {
      setIsVerified(true);
    } else {
      setIsVerified("");
    }
  };

  const sendEmail = (e) => {
    e.preventDefault();

    if (!isVerified) {
      setModalOpen(true);
      setAnswer("");
      errRef.current.focus();

      // if modal on -> scroll is not working
      document.body.style.overflow = "hidden";

      return null;
    }

    if (isVerified) {
      emailjs
        .sendForm(
          process.env.REACT_APP_SERVICE_KEY,
          process.env.REACT_APP_TEMPLATE_KEY,
          form.current,
          process.env.REACT_APP_USER_KEY
        )
        .then(
          () => {
            alert("Email Successfully Sent!");
            e.target.reset();
            setIsVerified(false);
            setAnswer("");
          },
          () => {
            alert("Please send an email to dev.frank.kim@gmail.com");
          }
        );
    }
  };

  return (
    <section id="contact" className="content">
      <Scroll modalOpen={modalOpen} />
      <h5>What’s Next?</h5>
      <h2>Get In Touch</h2>
      <div className="container contact__container">
        {/* ==========  left - contact form  ==========*/}
        <div className="contact__options">
          <article className="contact__option">
            <MdOutlineMarkEmailUnread className="contact__option-icon" />
            <h4>Email</h4>
            <a
              href="mailto:dev.frank.kim@gmail.com"
              target="__blank"
              className="contact-btn"
            >
              Send an email
            </a>
          </article>
          <article className="contact__option">
            <AiOutlineLinkedin className="contact__option-icon" />
            <h4>LinkedIn</h4>
            <a
              href="https://www.linkedin.com/in/frankimkh/"
              target="__blank"
              className="contact-btn"
            >
              Send a message
            </a>
          </article>
          <article className="contact__option">
            <SiNotion className="contact__option-icon" />
            <h4>Notion</h4>
            <a
              href="https://misty-suggestion-37a.notion.site/Frank-Kim-6ce6b804f1fb4dca988c48de60624842"
              target="__blank"
              className="contact-btn"
            >
              Check me out
            </a>
          </article>
        </div>

        {/*  ======= Modal Popup ======= */}
        <div className={modalOpen ? "openModal" : "modal"}>
          <Popup modalOpen={modalOpen} setModalOpen={setModalOpen} />
        </div>

        {/*  ======= Form ======= */}
        <form ref={form} onSubmit={sendEmail} className="contact__form">
          <input
            placeholder="Enter your email address"
            type="email"
            name="sender"
            required
          />
          <input
            placeholder="Enter your name"
            type="text"
            name="name"
            required
          />
          <input placeholder="Subject" type="text" name="subject" required />
          <textarea
            placeholder="Message..."
            cols="30"
            rows="10"
            name="message"
            required
          ></textarea>

          <input
            ref={errRef}
            className={modalOpen ? "errFocus" : ""}
            value={answer}
            onChange={checkAnswer}
            type="text"
            placeholder="Verification: What is 5 x 5 = ?"
            required
          />

          {/* <Button> */}
          {/* send a message */}
          <button className="btn btn-primary">Send Message</button>
          {/* </Button> */}
        </form>
      </div>
    </section>
  );
}

export default Contact;

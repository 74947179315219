import "./header.css";

import { BsLinkedin, BsGithub } from "react-icons/bs";
import { SiNotion } from "react-icons/si";
import { FaVimeoSquare } from "react-icons/fa";

function HeaderSM() {
  return (
    <div>
      <div className="header__socials">
        <a
          href="https://github.com/devfrankkim"
          target="_blank"
          rel="noreferrer"
          className="contact-btn">
          <BsGithub />
        </a>
        <a
          href="https://misty-suggestion-37a.notion.site/2c8a9c1f48d34aa98f996cda4ec5f78a?v=1a51ee451efb48c98710e4b4700654ae&pvs=4"
          target="_blank"
          rel="noreferrer"
          className="contact-btn">
          <SiNotion />
        </a>
        <a
          href="https://linkedin.com/in/frankimkh/"
          target="_blank"
          rel="noreferrer"
          className="contact-btn">
          <BsLinkedin />
        </a>
        <a
          href="https://velog.io/@frank_kim"
          target="_blank"
          rel="noreferrer"
          className="contact-btn">
          <FaVimeoSquare />
        </a>
      </div>
    </div>
  );
}

export default HeaderSM;

import { useState, useEffect } from "react";

import About from "./components/about/About";
import Contact from "./components/contact/Contact";
import Experience from "./components/expereince/Experience";
import Header from "./components/header/Header";
import Nav from "./components/nav/Nav";
import Portfolio from "./components/portfolio/Portfolio";

import Loading from "./components/loading/Loading";
import LightMode from "./components/LightMode/LightMode";
import SideProjects from "./components/SideProjects/SideProjects";

function App() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(false);
    const currentTheme = localStorage.getItem("theme");
    if (currentTheme === "light") {
      document.body.classList.add("light");
    } else {
      document.body.classList.remove("light");
    }
  }, []);

  return (
    <div>
      <LightMode />

      {loading ? (
        <Loading />
      ) : (
        <div>
          <Header />
          <Nav />
          <About />
          <Experience />
          <Portfolio />
          <SideProjects />
          <Contact />
        </div>
      )}
    </div>
  );
}

export default App;

import "./about.css";
import ME from "../../assets/myself.jpeg";
import { SiMeetup } from "react-icons/si";
import { IoEarthSharp } from "react-icons/io5";
import { FaLaptopCode } from "react-icons/fa";

function About() {
  return (
    <section id="about" className="content">
      <h5>Who Am I?</h5>
      <h2>About Me</h2>

      <div className="container about__container">
        {/* <div className="about__me">
          <div className="about__me-image">
            <img src={ME} alt="" />
          </div>
        </div> */}

        <div className="about__content">
          <div className="about__cards">
            <article className="about__card">
              <a href="https://github.com/devfrankkim" target="__blank">
                <FaLaptopCode className="about__icon" />
                <h5 className="about-btn">GitHub Repositories</h5>
                <small>100+ </small>
              </a>
            </article>
            <article className="about__card">
              <a href="http://codementoring.co/" target="__blank">
                <SiMeetup className="about__icon" />
                <h5 className="about-btn">Code Mentoring</h5>
                <small>3+ years since 2020</small>
              </a>
            </article>
            <article className="about__card">
              <IoEarthSharp className="about__icon" />
              <h5>Work Experience</h5>
              {/* <small> Korea, USA, Canada and the Philippines </small> */}
              <small> 한국, 미국, 캐나다, 필리핀 </small>
            </article>
          </div>
          <p>
            A developer with an international perspective and a passion for
            learning. Hardworking and eagerly embraces the challenge of solving
            problems, both with software and soft skills. Continuously educating
            myself at all levels of technical proficiency. Empathetic and
            positive when working with others in a team setting and one-on-one.
            Open and adaptable in changing environments.
          </p>
          <p>
            꾸준함을 중요시하고, 목표를 성취하는 데 재미를 느낍니다. 생산적인
            피드백을 즐깁니다. Meetup, Slack 플랫폼을 이용해 코드 멘토링
            커뮤니티를 2020년부터 관리했습니다. 4개 국가에서 다양한 분야의
            전문직과 협업 및 소통을 했습니다. 2개 국어를 구사합니다.
          </p>
        </div>
      </div>
    </section>
  );
}

export default About;

import { useState } from "react";
import "./nav.css";

import { VscFileCode } from "react-icons/vsc";
import { IoGitNetwork } from "react-icons/io5";
import { BiHomeHeart, BiUserCircle } from "react-icons/bi";
import { MdOutlineMarkEmailRead } from "react-icons/md";
import useIntersectionObservation from "../../hooks/useIntersection";

function Nav() {
  const [activeId, setActiveId] = useState("home");

  useIntersectionObservation(setActiveId);

  // const [active, setActive] = useState("#");
  // eslint-disable-next-line react-hooks/exhaustive-deps
  // const pageHandle = () => {
  //   console.log(window.pageYOffset);
  //   if (window.pageYOffset < 100) setActive("#");
  //   if (150 < window.pageYOffset && window.pageYOffset < 600)
  //     setActive("#about");
  //   if (700 < window.pageYOffset && 1000 < window.pageYOffset)
  //     setActive("#experience");
  //   if (1200 < window.pageYOffset && window.pageYOffset < 2700)
  //     setActive("#VscFileCode");
  //   if (3500 < window.pageYOffset) setActive("#contact");
  // };

  // useEffect(() => {
  //   const watch = () => {
  //     window.addEventListener("scroll", pageHandle);
  //   };

  //   watch();

  //   return () => {
  //     window.removeEventListener("scroll", pageHandle);
  //   };
  // }, [setActive]);

  return (
    <nav className="nav-container">
      <a
        href="#home"
        className={activeId === "home" ? "active" : ""}
        // onClick={() => setActive("#")}
      >
        <BiHomeHeart />
      </a>
      <a
        href="#about"
        className={activeId === "about" ? "active" : ""}
        // onClick={() => setActive("#about")}
      >
        <BiUserCircle />
      </a>
      <a
        href="#experience"
        className={activeId === "experience" ? "active" : ""}
        // onClick={() => setActive("#experience")}
      >
        <IoGitNetwork />
      </a>
      <a
        href="#portfolio"
        className={activeId === "portfolio" ? "active" : ""}
        // onClick={() => setActive("#portfolio")}
      >
        <VscFileCode />
      </a>
      <a
        href="#contact"
        className={activeId === "contact" ? "active" : ""}
        // onClick={() => setActive("#contact")}
      >
        <MdOutlineMarkEmailRead />
      </a>
    </nav>
  );
}

export default Nav;

// import resume from "../../assets/resume.pdf";

const resume =
  "https://misty-suggestion-37a.notion.site/Frank-Kim-6ce6b804f1fb4dca988c48de60624842?pvs=4";

function CTA() {
  return (
    <div className="cta">
      {/* <a href={resume} download className="btn"> */}
      <a href={resume} className="btn" target="_blank" rel="noreferrer">
        Resume
      </a>
      <a href="#contact" className="btn btn-primary content">
        Let's talk
      </a>
    </div>
  );
}

export default CTA;

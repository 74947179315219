// import Swiper core and required modules
import styled from "styled-components";

import { Keyboard, Scrollbar, Navigation, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/scrollbar";
import "swiper/css/navigation";
import "swiper/css/pagination";

import { SideProject_DATA } from "../../api/data.js";

function SideProjects() {
  return (
    <Wrapper id="testimonials">
      <h5>Miscellaneous Projects</h5>
      <h2>Snapshots</h2>

      <Swiper
        slidesPerView={1}
        centeredSlides={false}
        slidesPerGroupSkip={1}
        grabCursor={true}
        keyboard={{
          enabled: true,
        }}
        breakpoints={{
          520: {
            slidesPerView: 4,
            slidesPerGroup: 4,
          },
        }}
        scrollbar={false}
        navigation={true}
        pagination={{
          clickable: true,
        }}
        modules={[Keyboard, Scrollbar, Navigation, Pagination]}
        className="mySwiper container testimonials__container"
      >
        {SideProject_DATA.map(({ avatar, name, review }, index) => (
          <SwiperSlide className="testimonial" key={index}>
            <div className="wrapper__side-projects">
              {avatar && <Img className="side-projects" src={avatar} alt="" />}
            </div>

            <h5 className="desc">{name}</h5>
            <h6 className="stack">{review}</h6>
          </SwiperSlide>
        ))}
      </Swiper>
    </Wrapper>
  );
}

export default SideProjects;

const Wrapper = styled.section`
  .swiper-wrapper {
    display: flex;
    gap: 0.3rem;
  }

  .wrapper__side-projects {
    margin-bottom: 1rem;
  }
  .desc,
  .stack {
    text-align: center;
  }
  .stack {
    padding-bottom: 3rem;
  }

  .swiper-pagination-bullet {
    border: 5px solid var(--color-primary-variant);
  }

  .swiper-scrollbar {
    margin-top: 1rem;
    background: var(--color-primary-variant);
  }
`;

const Img = styled.img`
  height: 200px;

  /* @media screen and (min-width: 780px) {
    height: 300px;
  }

  @media screen and (max-width: 1024px) {
  } */
`;

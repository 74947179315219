import "./experience.css";
import { TiHtml5 } from "react-icons/ti";
import { ImTrello } from "react-icons/im";
import {
  SiReact,
  SiVisualstudio,
  SiTypescript,
  SiFlutter,
  SiDart,
  SiFastapi,
  SiPython,
  SiMongodb,
  SiSpring,
  SiMysql,
  SiHexo,
  SiPostman,
  SiStyledcomponents,
  SiFirebase,
  SiSlack,
  SiGithub,
} from "react-icons/si";
import { FaJava, FaAws } from "react-icons/fa";

import { IoLogoJavascript, IoLogoNodejs } from "react-icons/io";
import { GiDatabase } from "react-icons/gi";

function Experience() {
  return (
    <section id="experience" className="content">
      <h5>The Skills I Have</h5>
      <h2>My Experience</h2>

      <div className="container experience__container">
        {/* =========== frontend ===========*/}
        <div className="experience__frontend">
          <h3>Mainly Used</h3>
          <div className="experience__content">
            <article className="experience__details">
              <FaAws className="experience__details-icon" />
              <div>
                <h4>AWS</h4>
              </div>
            </article>
            <article className="experience__details">
              <IoLogoJavascript className="experience__details-icon" />
              <div>
                <h4>JavaScript</h4>
              </div>
            </article>
            <article className="experience__details">
              <SiReact className="experience__details-icon" />
              <div>
                <h4>React</h4>
              </div>
            </article>
            <article className="experience__details">
              <SiTypescript className="experience__details-icon" />
              <div>
                <h4>TypeScript</h4>
              </div>
            </article>
            <article className="experience__details">
              <SiDart className="experience__details-icon" />
              <div>
                <h4>Dart</h4>
              </div>
            </article>
            <article className="experience__details">
              <SiFlutter className="experience__details-icon" />
              <div>
                <h4>Flutter</h4>
              </div>
            </article>
            <article className="experience__details">
              <IoLogoNodejs className="experience__details-icon" />
              <div>
                <h4>Node JS</h4>
              </div>
            </article>
            <article className="experience__details">
              <TiHtml5 className="experience__details-icon" />
              <div>
                <h4>HTML</h4>
              </div>
            </article>
            <article className="experience__details">
              <SiStyledcomponents className="larger-icon experience__details-icon" />
              <div>
                <h4>CSS</h4>
              </div>
            </article>
            <article className="experience__details">
              <ImTrello className="experience__details-icon" />
              <div>
                <h4>Trello</h4>
              </div>
            </article>
            <article className="experience__details">
              <SiSlack className="experience__details-icon" />
              <div>
                <h4>Slack</h4>
              </div>
            </article>
            <article className="experience__details">
              <SiGithub className="experience__details-icon" />
              <div>
                <h4>GitHub</h4>
              </div>
            </article>
            <article className="experience__details">
              <SiVisualstudio className="experience__details-icon" />
              <div>
                <h4>VS Code</h4>
              </div>
            </article>
          </div>
        </div>
        {/* =========== Have Experience ===========*/}
        <div className="experience__backend">
          <h3>Have Experience</h3>
          <div className="experience__content">
            <article className="experience__details">
              <SiFastapi className="experience__details-icon" />
              <div>
                <h4> FastAPI</h4>
              </div>
            </article>
            <article className="experience__details">
              <SiPython className="experience__details-icon" />
              <div>
                <h4> Python</h4>
              </div>
            </article>
            <article className="experience__details">
              <SiMongodb className="experience__details-icon" />
              <div>
                <h4> MongoDB</h4>
              </div>
            </article>
            <article className="experience__details">
              <SiFirebase className="experience__details-icon" />
              <div>
                <h4> Firebase</h4>
              </div>
            </article>
            <article className="experience__details">
              <SiPostman className="experience__details-icon" />
              <div>
                <h4>Postman</h4>
              </div>
            </article>
            <article className="experience__details">
              <FaJava className="experience__details-icon" />
              <div>
                <h4>Java</h4>
              </div>
            </article>
            <article className="experience__details">
              <FaJava className="experience__details-icon" />
              <div>
                <h4>JSP</h4>
              </div>
            </article>
            <article className="experience__details">
              <SiSpring className="experience__details-icon" />
              <div>
                <h4> Spring </h4>
              </div>
            </article>

            <article className="experience__details">
              <GiDatabase className="experience__details-icon" />
              <div>
                <h4>Oracle</h4>
              </div>
            </article>
            <article className="experience__details">
              <SiMysql className="larger-icon experience__details-icon" />
              <div>
                <h4>MySQL</h4>
              </div>
            </article>
            <article className="experience__details">
              <SiHexo className="experience__details-icon" />
              <div>
                <h4>Hexo</h4>
              </div>
            </article>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Experience;

import "./loading.css";
import RingLoader from "react-spinners/RingLoader";
import BeatLoader from "react-spinners/BeatLoader";

function Loading() {
  return (
    <div className="loader">
      <RingLoader size="200" color="#36D7B7" position="absolute" />
      <BeatLoader size="10" color="#3aa590" position="absolute" />
    </div>
  );
}

export default Loading;

import "./header.css";
import CTA from "./CTA";
import HeaderSM from "./HeaderSM";

function Header() {
  return (
    <header id="home" className="content">
      <div className="container header__container">
        <h5>안녕하세요! </h5>
        {/* <h5>Hi! I am</h5> */}
        {/* <h1>Frank Kim</h1> */}
        <h1>개발자 김경현입니다. </h1>
        <h5 className="text-light">React Developer | JavaScript Developer</h5>
        <CTA />
        <HeaderSM />
      </div>
    </header>
  );
}

export default Header;

import "./lightmode.css";

function LightMode() {
  const switchTheme = () => {
    const currentTheme = localStorage.getItem("theme");
    if (currentTheme === "light") {
      localStorage.removeItem("theme");
      document.body.classList.remove("light");
    } else {
      localStorage.setItem("theme", "light");
      document.body.classList.add("light");
    }
  };

  return (
    <div className="switch">
      <label className="theme-switch" htmlFor="checkbox">
        <input type="checkbox" id="checkbox" />
        <div className="slider round" onClick={switchTheme}>
          <span>☀️</span>
          <span>🌙</span>
        </div>
      </label>
    </div>
  );
}

export default LightMode;

import "./portfolio.css";

import { PORTFOLIO_DATA } from "../../api/data";

import { BsYoutube } from "react-icons/bs";
import { SiGithub, SiNotion } from "react-icons/si";
import { MdComputer } from "react-icons/md";

function Portfolio() {
  return (
    <section id="portfolio" className="content">
      <h5>Builds and Collaborations</h5>
      <h2 id="VscFileCode">Projects</h2>

      <div className="container portfolio__container">
        {PORTFOLIO_DATA.map(
          ({
            id,
            image,
            title,
            team,
            notion,
            tools,
            github,
            demo,
            youtube,
          }) => (
            <article className="portfolio__item" key={id}>
              <div className="portfolio__item-image">
                <a href={demo} target="_blank" rel="noreferrer">
                  <img
                    className="portfolio__item-img"
                    src={image}
                    alt={title}
                  />
                </a>
              </div>
              <div className="title-container">
                <h3 className="title-container__title">{title}</h3>
                <div className="portfolio__item-cta">
                  {demo && (
                    <a href={demo} target="_blank" rel="noreferrer">
                      <MdComputer className="portfolio-btn" />
                    </a>
                  )}
                  {notion && (
                    <a href={notion} target="_blank" rel="noreferrer">
                      <SiNotion className="portfolio-btn" />
                    </a>
                  )}
                  {github && (
                    <a href={github} target="_blank" rel="noreferrer">
                      <SiGithub className="portfolio-btn" />
                    </a>
                  )}
                  {youtube && (
                    <a
                      href={youtube}
                      target="_blank"
                      rel="noreferrer"
                      className="portfolio-btn">
                      <BsYoutube />
                    </a>
                  )}
                </div>
              </div>
              {team && (
                // <div className="portfolio-tools"> &lt;Team Project&gt; </div>
                <div className="portfolio-tools"> &lt;팀 프로젝트&gt; </div>
              )}

              <div className="portfolio-tools">{tools}</div>
            </article>
          )
        )}
      </div>
    </section>
  );
}

export default Portfolio;

// const ButtonWrapper = styled.div`
//   display: flex;
//   justify-content: space-between;
//   align-items: center;
//   gap: 5px;
// `;

import weTalkLock from "../assets/wetalk-lock.png";
import webinarPortfolio from "../assets/webinar-portfolio.png";
import encer from "../assets/encer.png";
import promotive from "../assets/promotive.png";
import wishpollAdmin from "../assets/wishpoll_admin.png";
import wishpollMobile from "../assets/wishpoll_mobile.jpeg";
import starbucks from "../assets/starbucks.png";
import learnToCode1 from "../assets/learntocode1.png";
import instagram from "../assets/insta.png";
import movie from "../assets/movie.png";
import coin from "../assets/coin.png";
import modal from "../assets/modal.png";
import restaurant from "../assets/restaurant.png";
import scroll from "../assets/scroll.png";
import toggle from "../assets/toggle.png";
import video from "../assets/video.png";
import phone1 from "../assets/phone1.png";
import twitter from "../assets/twitter.png";
import dailydev from "../assets/dailydev.png";

export const PORTFOLIO_DATA = [
  {
    id: 9,
    image: wishpollAdmin,
    title: "Wishpoll Web Admin",
    tools: [
      "Atomic design | REST API | React | React Query | Jotai | AWS | Third-Party Integration | Styled-Components",
    ],
    demo: "https://wishpoll.kr",
    notion:
      "https://misty-suggestion-37a.notion.site/Wishpoll-web-dashboard-d775fe99d6634677921e8f22d8764db4",
  },
  {
    id: 8,
    image: wishpollMobile,
    title: "Wishpoll Mobile Application",
    tools: ["Atomic design | REST API | Dart | Flutter"],
    demo: "https://wishpoll.io",
    notion:
      "https://misty-suggestion-37a.notion.site/Wishpoll-mobile-application-772cf35d675649f4bf16b07cde39d935?pvs=4",
  },
  {
    id: 7,
    image: promotive,
    title: "Promotive",
    tools: [
      "React | TypeScript | Recoil | Chart.js | OpenWeather API | Styled-Components",
    ],
    github: "https://github.com/devfrankkim/Promotive",
    demo: "https://promotive.vercel.app",
  },
  {
    id: 6,
    image: encer,
    title: "Encer ",
    team: true,
    tools: ["React | Styled-Components | Vercel | Trello | Slack | Gather"],
    github: "https://github.com/Team-Elancer",
    demo: "https://encer.vercel.app/",
    youtube: "https://www.youtube.com/watch?v=ff_q0kFrhsc",
  },
  // {
  //   id: 5,
  //   image: dailydev,
  //   title: "Daily Dev",
  //   team: true,
  //   tools: ["React | Styled-Components | JSON-server"],
  //   github: "https://github.com/TEAM-Daily-Dev/DD-Daily-Dev",
  // },
  {
    id: 4,
    image: webinarPortfolio,
    title: "Code Mentoring",
    team: true,
    tools: [
      "Hosting coding workshop - focusing mainly on web development, which includes HTML, CSS, and JavaScript.",
    ],
    github: "https://github.com/code-mentoring",
    demo: "https://www.meetup.com/toronto-code-mentoring/",
    youtube: "https://www.youtube.com/@CodeMentoring/videos",
  },

  {
    id: 3,
    image: learnToCode1,
    title: "Learn to Code",
    team: true,
    tools: ["GitHub-Wiki Agile Slack"],
    github: "https://github.com/code-mentoring/learn/wiki",
    demo: "https://learn.codementoring.co/login",
  },

  {
    id: 2,
    image: instagram,
    title: "Instagram Clone",
    tools: ["HTML CSS JavaScript"],
    github: "https://github.com/devfrankkim/instagram-clone",
    demo: "https://instagram-heyfranksmile.vercel.app/",
  },
  {
    id: 1,
    image: weTalkLock,
    title: "WeTalk",
    tools: ["HTML CSS JavaScript"],
    github: "https://github.com/devfrankkim/wetalk",
    demo: "https://devfrankkim.github.io/wetalk/",
  },
];

export const SideProject_DATA = [
  {
    avatar: movie,
    name: "Movie API",
    review: "React, Styled-Components",
  },
  {
    avatar: twitter,
    name: "Twitter Clone",
    review: "React, Firebase",
  },
  {
    avatar: coin,
    name: "Coin Tracker API",
    review: "React, Styled-Components",
  },
  {
    avatar: scroll,
    name: "Scroll practice",
    review: "HTML, CSS, JavaScript",
  },
  {
    avatar: restaurant,
    name: "Restaurant page",
    review: "HTML, Bootstrap",
  },
  {
    avatar: toggle,
    name: "Toggle",
    review: "HTML, CSS, JavaScript",
  },
  {
    avatar: modal,
    name: "Modal practice",
    review: "HTML, CSS, JavaScript",
  },
  {
    avatar: video,
    name: "DOM API",
    review: "HTML, CSS, JavaScript",
  },
  {
    avatar: starbucks,
    name: "Starbucks Clone",
    review: "HTML, CSS",
  },
  {
    avatar: phone1,
    name: "Receipe Dribble",
    review: "HTML, CSS",
  },
];

import "./popup.css";
import { useEffect } from "react";

function Popup({ setModalOpen }) {
  useEffect(() => {
    const closeWithESC = (e) => {
      if (e.key === "Escape") {
        closeModal();
      }
    };

    window.addEventListener("keydown", closeWithESC);
    return () => window.removeEventListener("keydown", closeWithESC);
  }, []);

  const closeModal = () => {
    setModalOpen(false);
    // Scroll working
    document.body.style.overflow = "unset";
  };

  return (
    <div className="modal__container">
      <div>Verification: </div>
      <div>What is 5 x 5 = ?</div>
      <button onClick={closeModal}>Cancel</button>
    </div>
  );
}

export default Popup;
